import { computed } from 'vue'
import { useState } from '#app'

// Define the structure of the device state
interface DeviceState {
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
    isMobileOrTablet: boolean
}

export const useDevice = () => {
    // Explicitly type the global state
    const deviceState = useState<DeviceState>('deviceState', () => ({
        isMobile: false,
        isTablet: false,
        isDesktop: false,
        isMobileOrTablet: false,
    }))

    // Return destructured, computed properties
    return {
        isMobile: computed(() => deviceState.value.isMobile),
        isTablet: computed(() => deviceState.value.isTablet),
        isDesktop: computed(() => deviceState.value.isDesktop),
        isMobileOrTablet: computed(() => deviceState.value.isMobileOrTablet),
    }
}
